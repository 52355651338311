<template>
	<div class="recomendations">
		<div class="recomendations__title">Полезные статьи и рекомендации</div>

		<div class="recomendations__list">
			<router-link
				v-for="item in content"
				:key="item.id"
				:to="`/profile/faq/articles/${item.alias}`"
				class="recomendations__article article-recomend"
			>
				<div class="article-recomend__img">
					<tk-image :src="item.preview" width="310" height="160" />
				</div>
				<div class="article-recomend__title-container">
					<div class="article-recomend__title">
						{{ item.title }}
					</div>
				</div>
			</router-link>
		</div>
    <div class="recomendations__pagination">
      <tk-pagination
          v-if="meta && meta.to"
          :offsetPage="meta.page"
          :content="meta"
          @next="get"
      />
    </div>
	</div>
</template>

<script>
import mods from "@/constants/mods";

export default {
	data() {
		return {
			content: [],
      meta: null,

      getAbortController: new AbortController(),
		}
	},
  computed: {
    articlesType() {
      let type = 0
      if (this.$store.getters.mode === mods.GUEST) {
        type = 9
      } else if (this.$store.getters.mode === mods.MASTER) {
        type = 8
      }
      return type
    }
  },
  methods: {
    async get(page) {
      this.getAbortController.abort()
      this.getAbortController = new AbortController()

      const response = await this.$api.articles.getAll(
        { limit: 6, page: page, type: this.articlesType },
        { controller: this.getAbortController}
      )

      if (response && response.status) {
        this.content = response.response.items
        this.meta = response.response.meta
      }
    }
  },
	async beforeMount() {
		await this.get(1)
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>

<style lang="sass">
.recomendations
  position: relative
  &__title
    font-weight: bold
    font-size: 24px
    line-height: 24px
    margin-bottom: 30px
  &__pagination
    margin-top: 50px
  &__list
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    justify-items: center
    grid-gap: 20px
  &__form
    width: 420px
    position: relative
    &:before
      content: url('~@/assets/images/svg/magnifying-glass.svg')
      position: absolute
      top: 15px
      left: 15px
  &__input
    width: 100%
    height: 55px
    padding: 15px 15px 15px 45px
    background: #FFFFFF
    border: 1px solid #CBCBCB
    border-radius: 12px
    font-size: 12px
    line-height: 12px
    margin-bottom: 30px
    caret-color: #1AC386
    &::-webkit-search-cancel-button
      -webkit-appearance: none
      background: url('~@/assets/images/svg/close-gray.svg') center no-repeat
      width: 20px
      height: 20px
  &__link
    position: relative
    display: flex
    align-items: center
    height: 55px
    width: 530px
    padding-right: 25px
    padding-left: 15px
    background: #FFFFFF
    font-size: 14px
    line-height: 12px
    border-radius: 12px
    box-shadow: 0px 0px 17px -6px lightgrey
    margin-bottom: 10px
    cursor: pointer
    transition: 0.1s linear
    &:before
      content: url('~@/assets/images/svg/arrow-right.svg')
      position: absolute
      right: 15px
    &:hover
      background: #1AC386
      color: #ffffff
      &.recomendations__link::before
        content: url('~@/assets/images/svg/arrow-right-white.svg')
  .article-recomend
    display: block
    width: 100%
    max-width: 310px
    text-decoration: none
    border-radius: 11px
    box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.1)
    overflow: hidden

    &:nth-child(3n)
      margin-right: 0

    &__title-container
      height: 80px
      padding: 15px

      background-color: #FFFFFF
      border-radius: 0px 0px 11px 11px

    &__title
      font-style: normal
      font-weight: bold
      font-size: 16px
      line-height: 19px
      color: #3B3F47

    &__img
      width: 310px
      height: 160px
      overflow: hidden
      img
        object-fit: cover

// 540
@media screen and (max-width: 800px)
  .recomendations
    width: 100%
    .article-recomend
      width: 100%
      &__img
        img
          width: 100%
          height: auto
    &__list
      justify-content: center
</style>
